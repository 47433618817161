<template>
  <!-- 订单选项卡 -->
  <div class="member-order">
    <XtxTabs v-model:active="active">
      <XtxTabTitle v-for="status in purchaseStatus" :key="status.name">
        {{ status.label }}
      </XtxTabTitle>
    </XtxTabs>
    <div class="body">
      <div class="column goods">
        <Select v-model="model" style="width: 170px">
          <Option
            v-for="item in selectTimeList"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </Option>
        </Select>
        <span style="margin-left: 40px">订单详情</span>
      </div>
      <div class="column consignee">收货人</div>
      <div class="column amount">订单总金额</div>
      <div class="column state">订单状态</div>
      <div class="column action">操作</div>
    </div>
    <!-- 订单列表 -->
    <div v-if="orderList" class="order-list">
      <!-- <div v-if="loading" class="loading"></div> -->
      <template v-if="orderList && orderList.rows">
        <PurchaseOrderItem
          v-for="item in orderList.rows"
          :key="item.id"
          :order="item"
          @onCancelOrder="onCancelOrder"
          @onReloadOrderList="getData"
          @onViewLogistics="onViewLogistics"
        />
      </template>
      <div v-if="orderList?.rows?.length === 0" class="none">暂无数据</div>
      <XtxPagination
        v-if="orderList && totalPage > 1"
        v-model:page="reqParams.page"
        :pageSize="reqParams.pageSize"
        :count="totalCount"
      />
    </div>
  </div>
  <div style="float: right" class="table-page">
    <Page :total="orderList.records" @on-change="onChange" />
  </div>
</template>

<script>
import { purchaseStatus } from '@/api/constants'
import PurchaseOrderItem from '@/views/member/order/purchase/PurchaseOrderItem'
import VueEvent from '@/model/event'
// import { ref } from 'vue'
export default {
  name: 'PurchaseOrderListPage',
  components: { PurchaseOrderItem },
  data () {
    return {
      orderList: {},
      active: 0,
      model: '',
      selectTimeList: [
        {
          value: '0',
          label: '全部',
        },
        {
          value: '3',
          label: '三个月',
        },
        {
          value: '12',
          label: '近1年',
        },
      ],
      currentStatus: '', // 当前状态
      numPage: 1, // 当前页号
      postData: {
        orderId: '',
        productName: '',
        formate: '',
        companyName: '',
        beginDate: '',
        endDate: '',
        status: '',
        page: 1,
        rows: 10,
        sidx: 'order_time',
        sord: 'desc',
      },
    }
  },
  created () {
    this.init()
    this.bankCall() // 银行支付回调
  },
  mounted () {
    this.RemovePaymenteOrderMitt()
    this.purchaseOrderSrarchMitt()
    this.orderPurchaseSrarchClearMitt()
  },
  methods: {
    init () {
      this.pageData()
    },
    bankCall () {
      if (JSON.stringify(this.$route.query) === '{}') {
        this.$store
          .dispatch('PaymentUpdatePayStatus', this.$route.query)
          .then((response) => {})
          .catch(() => {})
      }
    },
    pageData () {
      this.$store
        .dispatch('ListPaymentOrder', this.postData)
        .then((response) => {
          this.orderList = response.result
        })
        .catch(() => {})
    },
    onChange (val) {
      this.postData.page = val
      this.postData.status = this.currentStatus
      this.pageData()
      // this.pageData(val, this.currentStatus)
    },
    RemovePaymenteOrderMitt () {
      VueEvent.on('removePaymenteOrderMitt', (value) => {
        if (value) {
          this.postData.page = this.numPage
          this.pageData()
        }
      })
    },
    purchaseOrderSrarchMitt () {
      VueEvent.on('purchaseOrderSrarchMitt', (value) => {
        if (value) {
          this.postData = {
            orderId: value.orderId,
            productName: value.productName,
            formate: value.formate,
            companyName: value.companyName,
            beginDate: value.beginTime,
            endDate: value.endTime,
            status: '',
            page: 1,
            rows: 10,
            sidx: 'order_time',
            sord: 'desc',
          }
          this.$store
            .dispatch('ListPaymentOrder', this.postData)
            .then((response) => {
              this.orderList = response.result
            })
            .catch(() => {})
        }
      })
    },
    orderPurchaseSrarchClearMitt () {
      VueEvent.on('orderPurchaseSrarchClearMitt', (value) => {
        this.active = 0
        this.postData = {
          orderId: '',
          productName: '',
          formate: '',
          companyName: '',
          beginDate: '',
          endDate: '',
          status: '',
          page: 1,
          rows: 10,
          sidx: 'order_time',
          sord: 'desc',
        }
        this.pageData()
      })
    },
  },
  watch: {
    active (val) {
      this.numPage = 1
      switch (val) {
        case 1:
          // 待确认
          this.currentStatus = 1
          this.postData.status = this.currentStatus
          this.pageData()
          break
        case 2:
          // 待签合同
          this.currentStatus = 2
          this.postData.status = this.currentStatus
          this.pageData()
          break
        case 3:
          // 待付款
          this.currentStatus = 3
          this.postData.status = this.currentStatus
          this.pageData()
          break
        case 4:
          // 待收货
          this.currentStatus = 6
          this.postData.status = this.currentStatus
          this.pageData()
          break
        case 5:
          // 已完成
          this.currentStatus = 100
          this.postData.status = this.currentStatus
          this.pageData()
          break

        case 6:
          // 已取消
          this.currentStatus = 1052
          this.postData.status = this.currentStatus
          this.pageData()
          break

        default:
          this.currentStatus = ''
          this.postData.status = this.currentStatus
          this.pageData()
          break
      }
    },
    model (val) {
      if (Number(val) === 0) {
        this.postData.beginDate = ''
        this.pageData()
        return
      }
      // x月前时间 的提单
      const nowdate = new Date()
      nowdate.setMonth(nowdate.getMonth() - val)
      const y = nowdate.getFullYear()
      const m = nowdate.getMonth() + 1
      const d = nowdate.getDate()
      const retrundate = y + '-' + m + '-' + d
      this.postData.beginDate = retrundate
      this.pageData()
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
  setup () {
    return {
      purchaseStatus,
    }
  },
}
</script>

<style lang="less" scoped>
.member-order {
  height: 100%;
  background: #fff;
  position: relative;
}

.order-list {
  background: #fff;
  // padding: 20px;
  position: relative;
}

.loading {
  height: calc(100% - 60px);
  width: 100%;
  min-height: 400px;
  position: absolute;
  left: 0;
  top: 60px;
  //   background: rgba(255, 255, 255, 0.9) url(../../../assets/images/loading.gif)
  //     no-repeat center 18%;
}

.none {
  height: 400px;
  text-align: center;
  line-height: 400px;
  color: #999;
  background: rgba(255, 255, 255, 0.9);
}
.body {
  display: flex;
  align-items: stretch;
  margin-bottom: 8px;
  background: #f5f5f5;
  .column {
    border-left: 1px solid #f5f5f5;
    text-align: center;
    padding: 20px;
    > p {
      padding-top: 10px;
    }
    &:first-child {
      border-left: none;
    }
    &.goods {
      flex: 3;
      padding: 10;
      align-self: center;
      text-align: left;
    }
    &.consignee {
      align-self: center;
      text-align: left;
      flex: 1;
    }
    &.state {
      align-self: center;
      text-align: left;
      flex: 1;
    }
    &.amount {
      flex: 1;
      align-self: center;
      text-align: left;
      // .red {
      //   color: @priceColor;
      // }
    }
    &.action {
      // width: 140px;
      flex: 1;
      align-self: center;
      text-align: center;
      a {
        display: block;
        &:hover {
          color: @xtxColor;
        }
      }
    }
  }
}
</style>
