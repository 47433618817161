<template>
  <div class="buying-page">
    <OrderPanel title="我的提单">
      <PurchaseSearch />
      <PurchaseOrderListPage />
    </OrderPanel>
  </div>
</template>

<script>
import OrderPanel from '@/views/member/order/components/OrderPanel'
import PurchaseSearch from '@/views/member/order/purchase/components/PurchaseSearch'
import PurchaseOrderListPage from '@/views/member/order/purchase/PurchaseOrderListPage'
import useOrderList from '@/hooks/member/useOrderList'
export default {
  name: 'PurchaseOrderPage',
  components: {
    OrderPanel,
    PurchaseSearch,
    // SubFilter,
    PurchaseOrderListPage,
  },
  data () {
    return {
      formItem: {
        search: '',
      },

    }
  },
  setup () {
		 const {
      active,
      // orderList,

    } = useOrderList()
		 return {
      active,

    }
	},
}
</script>

<style lang="less" scoped>
.buying-page {
  :deep(.xtx-carousel) .carousel-btn.prev {
    left: 5px;
  }
  :deep(.xtx-carousel) .carousel-btn.next {
    right: 5px;
  }
}
</style>
