<template>
  <div class="order-item">
    <div class="head">
      <span>下单时间：{{ order.createDate }}</span>
      <span>订单编号：{{ order.orderId }}</span>
      <span class="title">供应商: {{ order.companyName }}</span>
    </div>
    <div class="body">
      <div class="column goods">
        <ul>
          <li>
            <img :src="order.img" style="width: 90px; height: 90px" />
            <div class="info">
              <p class="name ellipsis-2">
                {{ order.goodsName }}
              </p>
              <p class="attr ellipsis">
                <span>类别:{{ order.categoryName }} &nbsp;</span>
                <span>规格:{{ order.formate }}</span>
              </p>
              <p class="attr ellipsis">
                <span v-if="order.tradeType == '2'" class="lable1">保证金</span>
                <span v-else class="lable2">全款</span>
              </p>
              <p class="attr ellipsis">
                <span class="price">
                  ¥
                  <Numeral :value="order.price" format="0,0.00"></Numeral>
                </span>

                <span style="color: #333333; font-weight: 550">数量:</span>
                <span class="price">
                  {{ order.goodsCount }}{{ order.unitName }}
                </span>
              </p>
              <p class="attr ellipsis">
                <span>{{ order.sendFromCityCode }}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="column consignee">{{ order.name }}</div>
      <div class="column amount">
        <p class="red">
          ¥
          <Numeral :value="order.orderAmount" format="0,0.00"></Numeral>
        </p>
        <!-- <p>（含运费：¥{{ order.postFee }}）</p>
        <p>在线付款</p> -->
      </div>
      <div class="column state">
        <p v-if="order.orderStatus === 1">
          <span style="color: #333333">待确认</span>
        </p>
        <p v-if="order.orderStatus === 2">
          <span style="color: #333333">待签合同</span>
        </p>
        <p v-if="order.orderStatus === 3">
          <span style="color: #333333">待付款</span>
        </p>
        <p v-if="order.orderStatus === 4">
          <span style="color: #333333">待补仓单</span>
        </p>
        <p v-if="order.orderStatus === 5">
          <span style="color: #333333">待开票</span>
        </p>
        <p v-if="order.orderStatus === 6">
          <span style="color: #333333">待收货</span>
        </p>
        <p v-if="order.orderStatus === 7">
          <span style="color: #333333">待付保证金</span>
        </p>
        <p v-if="order.orderStatus === 100">
          <span style="color: #333333">已完成</span>
        </p>
        <p v-if="order.orderStatus === 1052">
          <span style="color: #333333">已取消</span>
        </p>
      </div>

      <div class="column action">
        <!-- <XtxButton
          v-if="order.orderState === 1"
          type="primary"
          size="small"
          @click="$router.push(`/checkout/pay?orderId=${order.id}`)"
        >
          立即付款
        </XtxButton>
        <XtxButton
          v-if="order.orderState === 3"
          type="primary"
          size="small"
          @click="onConfirmReceiptButtonClick(order.id)"
        >
          确认收货
        </XtxButton> -->
        <p>
          <a
            href="javascript:"
            @click="$router.push(`/user/purchase/${order.id}`)"
          >
            查看详情
          </a>
        </p>
        <p v-if="order.orderStatus === 7">
          <a href="javascript:" @click="paymentPay(order.tradeId)">付保证金</a>
        </p>
        <p v-if="order.orderStatus <= 3">
          <a href="javascript:" @click="removeOrder(order.id, order.tradeId)">
            取消订单
          </a>
        </p>
        <!--
        <p v-if="{ 2: 1, 3: 1, 4: 1, 5: 1 }[order.orderState]">
          <a
            href="javascript:"
            @click="$router.push(`/checkout/order?id=${order.id}`)"
          >
            再次购买
          </a>
        </p>
        <p v-if="{ 4: 1, 5: 1 }[order.orderState]">
          <a href="javascript:">申请售后</a>
        </p> -->
      </div>
    </div>
  </div>
  <Modal
    v-model="modal"
    class-name="vertical-center-modal"
    title="提示"
    @on-ok="ok"
    @on-cancel="cancel"
  >
    <p>是否需要取消订单</p>
  </Modal>
</template>

<script>
import useOrderItem from '@/hooks/member/useOrderItem'
import dayjs from 'dayjs'
import { orderStatus } from '@/api/constants'
import { ref } from 'vue'
import VueEvent from '@/model/event'
export default {
  name: 'PurchaseOrderItem',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      modal: false, // 提示框
      id: '',
      tradeId: '',
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {},
    removeOrder (id, tradeId) {
      this.id = id
      this.tradeId = tradeId
      this.modal = true
    },
    ok () {
      const postData = ref({
        id: this.id,
        tradeId: this.tradeId,
      })
      this.$store
        .dispatch('RemovePaymenteOrder', postData.value)
        .then((response) => {
          if (response.returnCode === '1') {
            this.$Message.info('取消订单成功')
            VueEvent.emit('removePaymenteOrderMitt', true)
          }
        })
        .catch(() => {})
    },
    cancel () {},
    paymentPay (tradeId) {
      const postData = ref({
        id: tradeId,
      })
      this.$Spin.show({
        render: (h) => {
          return h('div', [
            h(
              'div',
              {
                style: {
                  color: '#d12b23',
                  fontSize: '22px',
                },
              },

              '正在启动银行支付请不要离开页面。否则无法完成支付...',
            ),
          ])
        },
      })
      this.$store
        .dispatch('PaymentRequestPreOrderDepositPay', postData.value)
        .then((response) => {
          // this.$Spin.hide()
          if (response.returnCode === '1') {
            if (response.result.authUrl) {
              window.open(response.result.authUrl, '_self')
            }
          }
        })
        .catch(() => {})
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
  setup (props, { emit }) {
    const {
      count,
      onCancelButtonClick,
      onDeleteButtonClick,
      onConfirmReceiptButtonClick,
      onViewLogisticsButtonClick,
    } = useOrderItem(props, emit)

    return {
      count,
      dayjs,
      orderStatus,
      onCancelButtonClick,
      onDeleteButtonClick,
      onConfirmReceiptButtonClick,
      onViewLogisticsButtonClick,
    }
  },
}
</script>

<style lang="less" scoped>
.order-item {
  margin-bottom: 20px;
  border: 1px solid #f5f5f5;
  .head {
    height: 50px;
    line-height: 50px;
    background: #f5f5f5;
    padding: 0 20px;
    overflow: hidden;
    span {
      margin-right: 20px;
      &.down-time {
        margin-right: 0;
        float: right;
        i {
          vertical-align: middle;
          margin-right: 3px;
        }
        b {
          vertical-align: middle;
          font-weight: normal;
        }
      }
    }
    .title {
      // color: #409eff;
    }
    .del {
      margin-right: 0;
      float: right;
      color: #999;
    }
  }
  .body {
    display: flex;
    align-items: stretch;
    background: #fff;
    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;
      > p {
        padding-top: 10px;
      }
      &:first-child {
        border-left: none;
      }
      &.goods {
        flex: 3;
        padding: 0;
        align-self: center;

        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 10px;
            display: flex;
            &:last-child {
              border-bottom: none;
            }
            .image {
              width: 70px;
              height: 70px;
              border: 1px solid #f5f5f5;
            }
            .info {
              width: 220px;
              text-align: left;
              padding: 0 10px;
              p {
                margin-bottom: 5px;
                &.name {
                  height: 28px;
                  color: #333333;
                  font-weight: 550;
                }
                &.attr {
                  color: #999;
                  font-size: 12px;
                  span {
                    margin-right: 5px;
                  }
                  .lable1 {
                    background: #fff4e3;
                    font-size: 11px;
                    color: #dc9100;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;
                    border-radius: 4px;
                    padding: 2px;
                    width: 40px;
                    height: 22px;
                  }
                  .lable2 {
                    background: #ffeae9;
                    font-size: 11px;
                    color: #d12b23;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;
                    border-radius: 4px;
                    padding: 2px;
                    width: 40px;
                    height: 22px;
                  }
                  .price {
                    color: @priceColor;
                    font-size: 12px;
                    font-weight: 500;
                  }
                }
              }
            }
            .price {
              width: 100px;
            }
            .count {
              width: 80px;
            }
          }
        }
      }
      &.consignee {
        flex: 1;
        text-align: center;
        align-self: center;
        .green {
          color: @xtxColor;
        }
      }
      &.state {
        flex: 1;
        text-align: center;
        align-self: center;
        .green {
          color: @xtxColor;
        }
      }
      &.amount {
        flex: 1;
        text-align: center;
        align-self: center;
        .red {
          color: @priceColor;
        }
      }
      &.action {
        flex: 1;
        text-align: center;
        align-self: center;
        a {
          display: block;
          color: #2d9cdb;
          &:hover {
            color: @xtxColor;
          }
        }
      }
    }
  }
  .vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
