<template>
  <!-- 概览 -->
  <div class="merchandise-search">
    <div class="search">
      <Row :gutter="16">
        <Col span="8">
          <div class="search-input">
            <label style="width: 60px">订单号</label>
            <Input
              v-model="searchInfo.orderId"
              type="text"
              placeholder="请输入订单号"
            ></Input>
          </div>
        </Col>
        <Col span="8">
          <div class="search-input">
            <label style="width: 80px">产品名称</label>
            <Input
              v-model="searchInfo.productName"
              type="text"
              placeholder="请输入产品名称"
            ></Input>
          </div>
        </Col>
        <Col span="8">
          <div class="search-input">
            <label style="width: 80px">规格/型号</label>
            <Input
              v-model="searchInfo.formate"
              type="text"
              placeholder="请输入规格/型号"
            ></Input>
          </div>
        </Col>
      </Row>
      <br />
      <Row :gutter="16">
        <Col span="8">
          <div class="search-input">
            <label style="width: 80px">供应商</label>
            <Input
              v-model="searchInfo.companyName"
              type="text"
              placeholder="请输入采购商名称"
            ></Input>
          </div>
        </Col>
        <Col span="8">
          <div class="search-input">
            <label for="">日期</label>
            <DatePicker
              type="daterange"
              split-panels
              :model-value="dateValue"
              placeholder="选择日期 - 至"
              style="width: 200px"
              @on-change="onChange"
            ></DatePicker>
          </div>
        </Col>
        <Col span="8">
          <Button class="btn" @click="search()">查询</Button>
          <a style="margin-left: 15px; color: #d12b23" @click="clear">
            清空条件
          </a>
        </Col>
      </Row>
      <Divider />
    </div>
  </div>
</template>
<script>
import VueEvent from '@/model/event'
export default {
  name: 'PurchaseSearch',
  data () {
    return {
      dateValue: [],
      searchInfo: {
        orderId: '',
        productName: '',
        formate: '',
        companyName: '',
        beginTime: '',
        endTime: '',
      },
    }
  },
  methods: {
    search () {
      VueEvent.emit('purchaseOrderSrarchMitt', this.searchInfo)
    },
    onChange (val) {
      this.dateValue = val
      this.searchInfo.beginTime = val[0]
      this.searchInfo.endTime = val[1]
    },
    clear () {
      this.dateValue = []
      this.searchInfo = {
        orderId: '',
        productName: '',
        formate: '',
        companyName: '',
        beginTime: '',
        endTime: '',
      }
      VueEvent.emit('orderPurchaseSrarchClearMitt', true)
    },
  },

  destoryed () {
    VueEvent.all.clear()
  },
}
</script>
<style scoped lang="less">
.merchandise-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .search {
    width: 100%;
    margin-top: 20px;
    .search-input {
      display: flex;
      align-items: center;

      label {
        min-width: 40px;
        // text-align: right;
        margin-left: 6px;
        // padding-right: 10px;
        box-sizing: border-box;
      }
    }
  }
  .btn {
    width: 82px;
    background: #d12b23;
    height: 36px;
    margin-left: 10px;
    font-weight: 400;
    font-size: 13px;
    color: #fff;
    border: 1px solid #cfcdcd;
  }
}
/deep/ .ivu-input {
  border: 1px solid #dcdee2 !important;
  padding: 4px 7px !important;
}
</style>
